<!--
  Title        : メニュー
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template id="main">
<v-ons-page>
  <div class="background"></div>
  <Toolbar :action="toggleMenu"></Toolbar>
  <BottomToolbar></BottomToolbar>

  <div class="top">
    <div class="sponsor" v-cloak>
      <swiper :options="swiperOptions" v-if="festival_id !== 41 && festival_id !== 65">
        <swiper-slide v-for="(sponsor, index) in sponsorRes" :key="index">
          <img class="sponsor_img" :src="sponsor.sponsor_img" @click="sponsorDetail(sponsor.sponsor_id)" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <img class="sponsor_img" src="/img/banner/百舌鳥連合青年.png" v-if="festival_id == 41" @click="dedicatedPages()" />
      <img class="sponsor_img" src="/img/banner/公式HP河内長野市市制70周年_sponsor.png" v-if="festival_id == 65" @click="sponsorDetail(172)" />
    </div>
  </div>

  <img id="ban" :src="festival_banner">

  <div class="info" v-if="festival_type === 1 || festival_type === 2 || festival_type === 3">
    <div class="title">
      <label class="festival_name">{{ festival_name }}</label>
      <label v-if="festival_type == 1">だんじり情報</label>
      <label v-if="festival_type == 2">布団太鼓情報</label>
      <label v-if="festival_type == 3">祇園車情報</label>
    </div>
    <div>
      <v-ons-button class="btn left" id="danjiri" v-if="festival_type == 1" @click="MenuClick('alltownsmapbox')">
<!--      <v-ons-button class="btn left" id="danjiri" v-if="festival_type == 1" @click="requestOrientationPermission()">-->
        <div class="big_title" id="allTown">
          全町<br>
          <span>だんじり表示</span>
        </div>
        <div class="small_title" id="allTown">マップを見る>></div>
      </v-ons-button>
      <v-ons-button class="btn left" id="futon" v-if="festival_type == 2" @click="MenuClick('alltownsmapbox')">
        <div class="big_title" id="allTown">
          全町<br>
          <span>太鼓表示</span>
        </div>
        <div class="small_title" id="allTown">マップを見る>></div>
      </v-ons-button>
      <v-ons-button class="btn left" id="gion" v-if="festival_type == 3" @click="MenuClick('alltownsmapbox')">
        <div class="big_title" id="allTown">
          全町<br>
          <span>祇園車表示</span>
        </div>
        <div class="small_title" id="allTown">マップを見る>></div>
      </v-ons-button>
      <v-ons-button class="btn right" @click="MenuClick('notification')">
        <span id="notification_count" v-show="unreadFlg" class="notification notification--material">!</span>
        <div class="big_title" id="button-text">全町<br>お知らせ</div>
        <div class="small_title" id="button-text">一覧で見る>></div>
      </v-ons-button>
    </div>
  </div>

  <div class="town_select" v-cloak>
    <div id="town_select">
      <div class="title" v-if="festival_type == 1">町ごとのだんじり情報</div>
      <div class="title" v-if="festival_type == 2">町ごとの太鼓情報</div>
      <div class="title" v-if="festival_type == 3">町ごとの祇園車情報</div>
    </div>
    <div class="expandable-content">
      <div id="town" v-for="(town,index) in res" :key="index" @click="TownClick(town.town_id)" v-show="town.town_id !== 294">
        <img id="img" :src="town.icon" />
        <div id="town_name">{{town.town_name}}</div>
      </div>
    </div>
  </div>

  <!--  ダイアログ系-->
  <!--  ローディングダイアログ-->
  <v-ons-alert-dialog modifier="rowfooter"
                      :visible.sync="alertLoadingVisible">
    <Spinner message="データ取得中..." />
  </v-ons-alert-dialog>

  <!--  エラーダイアログ-->
  <v-ons-alert-dialog modifier="rowfooter"
                      :visible.sync="alertErrorVisible">
    <span slot="title"></span>
    只今準備中です。
    <template slot="footer">
      <v-ons-alert-dialog-button @click="alertErrorVisible = false">Ok</v-ons-alert-dialog-button>
    </template>
  </v-ons-alert-dialog>

  <BR /><BR /><BR />
</v-ons-page>
</template>

<script>
import Toolbar from '../Common/Toolbar';
import BottomToolbar from "../Common/BottomToolbar";
import Spinner from "vue-simple-spinner";

export default {
  name: "Menu",
  props: ['toggleMenu'],
  components: {
    Toolbar,
    BottomToolbar,
    Spinner,
  },
  data() {
    return {
      festival_type: this.$_festivalType(),
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        loop: true,
        autoplay: {
          delay: 5000, // 途切れなくループ
          reverseDirection: false,
        },
      },
      res: [],
      sponsorRes: [],
      alert: false,
      alertLoadingVisible: false,
      alertErrorVisible: false,
      festival_id: this.$_festivalIDS(),
      festival_name: this.$_FestivalData().festival_name,
      festival_banner: this.$_FestivalData().festival_banner,
      unreadFlg: false,

      terminal: this.$_Terminal(),
    }
  },
  created: function() {
    console.log(this.terminal)
    this.alertLoadingVisible = true   //Loadingダイアログ表示開始
    let self = this   //thenの中からdataを操作するため
    let apiVal = {
      festival_id: this.$_festivalIDS(),
      city_id: this.$_CityID()
    }
    let getResultAPI = this.$_mixAPI_postTownList(apiVal)
    getResultAPI.then(function(val){
      self.res = val
      self.$_setAllTownData(val)
    }).catch((err)=>{   //通信エラー時
      console.log(err);
      //self.alertErrorVisible = true   //エラーダイアログ表示
    }).finally(()=>{
      self.alertLoadingVisible = false    //Loadingダイアログを非表示
    });
    this.festivalData = this.$_FestivalData();
    this.festivalData.Sponsors.forEach(function(val){
      self.sponsorRes.push(val)
    })
  },
  mounted() {
    let self = this   //thenの中からdataを操作するため
    let unreadApiVal = {
      uuid: this.$_uuid(),
      festival_id: this.festival_id
    }
    let unread = this.$_mixAPI_logMonitoring(unreadApiVal)
    unread.then(function(val){
      self.unreadFlg = val.unread_flg
    });
  },
  methods: {
    sponsorDetail(val){
      this.$_setPage('Menu')
      return(
        this.$_setSponsor_id(val),
        //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
        this.$_SetmixPC_CurrentPage_Key('sponsorDetailTab')
      );
    },
    TownClick(val) {
      if(this.terminal == "iPhone"){
        // DeviceOrientationを使用する許可ダイアログを表示
        DeviceOrientationEvent.requestPermission().then(response => {
          // 許可がクリックされた場合
          if (response === "granted") {
            // deviceorientationイベントをlisten
            window.addEventListener('deviceorientation', this.handleOrientation);
          }
        }).catch(console.error).finally(() => {
          this.Town(val);
        });
      }else{
        this.Town(val);
      }
    },
    Town(val){
      this.alertLoadingVisible = true   //Loadingダイアログ表示開始
      let self = this   //thenの中からdataを操作するため
      let apiVal = {
        festival_id: this.$_festivalIDS(),
        town_id: val,
        uuid:this.$_uuid()
      }

      let getResultAPI = this.$_mixAPI_postTownData(apiVal)
      getResultAPI.then(function(val){
        let TownData = {
          festival_id: self.$_festivalIDS(),
          town_id: val[0].town_id,
          purchase_date: val[0].purchase_date,
          carpenter: val[0].carpenter,
          sculpture: val[0].sculpture,
          remarks: val[0].remarks,
          storehouse_latitude: val[0].storehouse_latitude,
          storehouse_longitude: val[0].storehouse_longitude,
          favorites: val[0].favorites,
          town_icon: val[0].town_icon,
          town_img: val[0].town_img,
          hut_img: val[0].hut_img,
          Sponsors: val['Sponsors'],
          GPS: val['GPS'],
        }
        self.$_setTownData(TownData);
        self.$_setTownID(val[0].town_id);
        //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
        self.$_SetmixPC_ActiveTabIndex(0)

        //タブ用タイトルアイコンデータセット
        let icon4tab = {
          Title: val[0].town_name,
          Icon: val[0].town_icon
        }
        self.$_SetmixPC_TitleIcon4Tab(icon4tab);
        self.$_SetmixPC_CurrentPage_Key('tab');

      }).catch((err)=>{   //通信エラー時
        console.log(err);
        self.alertErrorVisible = true   //エラーダイアログ表示
      }).finally(()=>{
        self.alertLoadingVisible = false    //Loadingダイアログを非表示
      });
    },
    MenuClick(val){
      if(this.terminal == "iPhone"){
        // DeviceOrientationを使用する許可ダイアログを表示
        DeviceOrientationEvent.requestPermission().then(response => {
          // 許可がクリックされた場合
          if (response === "granted") {
            // deviceorientationイベントをlisten
            window.addEventListener('deviceorientation', this.handleOrientation);
          }
        }).catch(console.error).finally(() => {
          this.Menu(val);
        });
      }else{
        this.Menu(val);
      }
    },
    Menu(val){
      //PageListを取得
      let PageL = this.$_mixPC_PageList();
      let CurComponent = PageL.filter(e => e.Key === val);
      this.$_SetmixPC_CurrentPage(CurComponent[0]);
    },
    handleOrientation(event) {
      // デバイスの方向を取得した後の処理
      console.log('デバイスの向き:', event.alpha);
      this.orientation = event
    },
    dedicatedPages(){
      this.alertLoadingVisible = true   //Loadingダイアログ表示開始
      this.$_setPage('')
      this.$_setTownID(294);
      let self = this   //thenの中からdataを操作するため
      let apiVal = {
        festival_id: this.$_festivalIDS(),
        town_id: 294,
        uuid: this.$_uuid()
      }
      let getResultAPI = this.$_mixAPI_postTownData(apiVal)
      getResultAPI.then(function(val){
        let TownData = {
          festival_id: self.$_festivalIDS(),
          town_id: val[0].town_id,
          purchase_date: val[0].purchase_date,
          carpenter: val[0].carpenter,
          sculpture: val[0].sculpture,
          remarks: val[0].remarks,
          storehouse_latitude: val[0].storehouse_latitude,
          storehouse_longitude: val[0].storehouse_longitude,
          favorites: val[0].favorites,
          town_icon: val[0].town_icon,
          town_img: val[0].town_img,
          hut_img: val[0].hut_img,
          Sponsors: val['Sponsors'],
          GPS: val['GPS'],
        }
        self.$_setTownData(TownData);
        self.$_setTownID(val[0].town_id);
        //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
        self.$_SetmixPC_ActiveTabIndex(0)
        self.$_SetmixPC_CurrentPage_Key('futontab');

      }).catch((err)=>{   //通信エラー時
        console.log(err);
        self.alertErrorVisible = true   //エラーダイアログ表示
      }).finally(()=>{
        self.alertLoadingVisible = false    //Loadingダイアログを非表示
      });
    }
  }
}
</script>

<style scoped>
  [v-cloak] {
    display: none;
  }
  .background {
    background: black;
  }
  .top {
    background-image: url("/img/parts/スライダー-背景.jpg");
    background-size: 100% 260px;
    background-repeat: no-repeat;
    width: 100%;
    height: 260px;
  }
  .sponsor {
    border-radius: 20px;
    top: 10px;
    width: calc(100% - 40px);
    padding: 20px;
  }
  .sponsor_img {
    width: 100%;
    height: 210px;
  }
  #ban {
    width: 100%;
  }
  .info {
    background: #fffbea;
    border-radius: 20px;
    margin: 5% 2%;
    padding: 3%;
  }
  .title {
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    font-size: 20px;margin: 0 1%;
  }
  .festival_name {
    white-space: pre-line;
  }
  .btn {
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000,
    -1px 1px 0 #000, 1px -1px 0 #000,
    0 1px 0 #000,  0 -1px 0 #000,
    -1px 0 0 #000, 1px 0 0 #000;
    box-shadow: 2px 3px 2px rgba(0, 0, 0, .3);
    border-radius: 20px;
    overflow: visible;
    margin: 2% 1% 1% 2%;
    width: 47%;
    height: 180px;
  }
  #danjiri {
    background-image: url("/img/parts/だんじり表紙2.png"), url("/img/parts/金色屏風-背景.jpg");
    background-position: right bottom, center top;
    background-size: 60%, 1000%;
    background-repeat: no-repeat, repeat;
  }
  #futon {
    background-image: url("/img/parts/taiko_2024.png"), url("/img/parts/金色屏風-背景.jpg");
    background-position: right bottom, center top;
    background-size: 75%, 1000%;
    background-repeat: no-repeat, repeat;
  }
  #gion {
    background-image: url("/img/parts/nakatsu2.png"), url("/img/parts/金色屏風-背景.jpg");
    background-position: right bottom, center top;
    background-size: 100%, 1000%;
    background-repeat: no-repeat, repeat;
  }
  .right {
    background-image: url("/img/parts/メガホン-透過.png"), url("/img/parts/金色屏風-背景.jpg");
    background-position: right bottom, center top;
    background-size: 90%, 1000%;
    background-repeat: no-repeat, repeat;
  }
  .big_title {
    text-align: left;
    font-size: 22px;
    padding-top: 70px;
  }
  .small_title {
    text-align: right;
    font-size: 14px;
  }
  .town_item img {
    width: 70px;
    height: auto;
    margin-top:5px;
  }
  .town_item p{
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-size: 30px;
    position: relative;
    top: -10px;
    text-shadow:  2px  2px 10px #f9df95,
    -2px  2px 10px #f9df95,
    2px -2px 10px #f9df95,
    -2px -2px 10px #f9df95;
  }
  .town_select {
    background: #fffbea;
    border-radius: 15px;
    width: 96%;
    margin: 2%;
  }
  #town_select {
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    padding: 2% 0 0 3%;
    display: flex;
    flex-wrap: wrap;
  }
  .expandable-content {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding: 0 1% 1%;
  }
  #town {
    background: url("/img/parts/金色屏風-背景.jpg");
    border: solid 1px black;
    border-radius: 10px;
    padding: 0;
    margin: 2% 2%;
    width: 45%;
    height: 45px;
  }
  #town_name {
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    text-align: center;
    font-size: 15px;
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000,
    -1px 1px 0 #000, 1px -1px 0 #000,
    0 1px 0 #000,  0 -1px 0 #000,
    -1px 0 0 #000, 1px 0 0 #000;
    padding-top: 10px;
  }
  #img {
    margin: 3px 5px 0;
    width: 40px;
    height: 40px;
    float: left;
  }
  #notification_count {
    box-shadow: inset 5px 5px 8px 0 rgba(0, 0, 0, .1);
    position: absolute;
    font-size: 20px;
    top: -7px;
    right: -7px;
    width: 30px;
    height: 30px;
    padding: 5px;
  }
  .banner {
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    text-align: left;
    font-size: 20px;
    padding: 4% 5%
  }
</style>