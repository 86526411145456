<!--
  Title        : 町お知らせ
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>

        <!--リロード機能-->
        <v-ons-pull-hook
                id="pull-hook"
                :action="loadItem"
                @changestate="state = $event.state"
        >
            <span v-show="state === 'initial'">
                <v-ons-icon icon="long-arrow-down" size="30px" style="color: white"></v-ons-icon>
            </span>
            <span v-show="state === 'preaction'">
                    <v-ons-icon icon="long-arrow-up" size="30px" style="color: white"></v-ons-icon>
            </span>
            <span v-show="state === 'action'">
                <Spinner/>
            </span>
        </v-ons-pull-hook>

        <div class="main" v-show="res">
            <div class="body" v-for="(value, key) in res" :key="key">
                <div>
                    <div class="title">
                        <p id="title">{{ value.title }}</p>
                        <v-ons-icon id="more" icon="md-more" @click="dialogVisible = true; news_id = value.news_id" size="24px"></v-ons-icon>
                    </div>
                    <carousel :per-page="1" :pagination-size="5" :pagination-padding="5">
                        <slide v-for="(value, index) in value.contents_path" :key=index>
                            <viewer class="viewer">
                                <img id="img" :src="value">
                            </viewer>
                        </slide>
                    </carousel>
                    <p id="explanation">
                        {{ value.description }}
                    </p>
                    <p id="date-time">
                        {{ value.created_at }}
                    </p>
                    <p id="border"></p>
                </div>
            </div>
        </div>

        <!--InfiniteLoadingコンポーネントを定義 -->
        <infinite-loading class="loading" @infinite="infiniteHandler" spinner="spiral">
            <div slot="spinner">ロード中...</div>
            <div slot="no-more"></div>
            <div slot="no-results">まだお知らせがありません。</div>
        </infinite-loading>

        <!--編集・削除・違反通告・非表示ボタン-->
        <v-ons-dialog
                cancelable
                :visible.sync="dialogVisible"
        >
            <div class="submission-dialog">
                <p id="delete" @click="alertPassVisible = true" >
                    削除
                </p>
                <p id="edit" @click="alertEditPassVisible = true">
                    編集
                </p>
                <p id="cancel" @click="dialogVisible = false">
                    キャンセル
                </p>
            </div>
        </v-ons-dialog>

        <!--パス確認ダイアログ-->
        <v-ons-alert-dialog modifier="rowfooter"
                            :visible.sync="alertPassVisible">
            投稿者のみ削除できます。<br>
            投稿時に設定したパスワードを <br>
            入力して下さい。
            <div class="center">
                <v-ons-input placeholder="パスワードを入力" float
                             v-model="message" id="newsPassword" />
            </div>
            <div class="dialogbtn">
                <v-ons-alert-dialog-button @click="handleCancel">
                    キャンセル
                </v-ons-alert-dialog-button>
                <v-ons-alert-dialog-button id="handledel" @click="handleDel">
                    削除
                </v-ons-alert-dialog-button>
            </div>
        </v-ons-alert-dialog>
        <!--削除成功ダイアログ-->
        <v-ons-alert-dialog modifier="rowfooter"
                            :visible.sync="alertSuccessDeleteVisible">
            削除しました。
            <template slot="footer">
                <v-ons-alert-dialog-button @click="Post">OK</v-ons-alert-dialog-button>
            </template>
        </v-ons-alert-dialog>

        <!--パス確認 編集ダイアログ-->
        <v-ons-alert-dialog modifier="rowfooter"
                            :visible.sync="alertEditPassVisible">
            投稿者のみ編集できます。<br>
            投稿時に設定したパスワードを <br>
            入力して下さい。
            <div class="center">
                <v-ons-input placeholder="パスワードを入力" float
                             v-model="message" id="password" />
            </div>
            <div class="dialogbtn">
                <v-ons-alert-dialog-button @click="handleEditCancel">
                    キャンセル
                </v-ons-alert-dialog-button>
                <v-ons-alert-dialog-button id="handleedit" @click="handleEdit">
                    編集
                </v-ons-alert-dialog-button>
            </div>
        </v-ons-alert-dialog>

        <!--エラーダイアログ-->
        <v-ons-alert-dialog modifier="rowfooter"
                            :visible.sync="alertErrPassVisible">
            <span slot="title">エラー</span>
            {{ ErrMessage }}
            <template slot="footer">
                <v-ons-alert-dialog-button @click="alertErrPassVisible = false">Ok</v-ons-alert-dialog-button>
            </template>
        </v-ons-alert-dialog>
        <BR /><BR /><BR />
    </v-ons-page>
</template>

<script>
    import Spinner from "vue-simple-spinner";

    export default {
        name: "News",
        components: {
            Spinner
        },
        data() {
            return {
                res: null,
                resid: 0,
                start: 0,
                end: 100,
                submission_id: "",
                message: "",
                isShowPost: true,
                dialogVisible: false,
                dialogVisibleAdmin: false,
                alertViolation: false,
                alertReject: false,
                alertSuccessViolationVisible: false,
                alertSuccessRejectVisible: false,
                alertPassVisible: false,
                alertEditPassVisible: false,
                alertErrorVisible: false,
                alertErrPassVisible: false,
                alertSuccessDeleteVisible: false,
                state: 'initial',
                ErrMessage: ''
            }
        },
        mounted: function() {
            let uuid = this.$_uuid()
            uuid !== '' ? this.isShowPost = true : this.isShowPost = false
        },
        filters: {
            readmore(text,length,suffix){
                var text_array = text.split('');
                var count = 0;
                var str = '';
                for (var i = 0; i < text_array.length; i++) {
                    var n = escape(text_array[i]);
                    if (n.length < 4) count++;
                    else count += 2;
                    if (count > length) {
                        return str + suffix;
                    }
                    str += text.charAt(i);
                }
                return text;
            }
        },
        methods: {
            loadItem(done) {

                let self = this;
                let params = {
                    festival_id: this.$_festivalIDS(),
                    town_id: this.$_TownID(),
                    disclosure_range_flg: 2
                }
                let getResultAPI = this.$_mixAPI_getDanjiriNews(params);

                getResultAPI.then(function(val){

                    // 表示するデータが無い場合
                    if(val.DanjiriNews.length == 0){
                        setTimeout(() => {
                            done();
                        }, 1000);
                        return;
                    }

                    setTimeout(() => {
                        self.res = val.DanjiriNews;
                        self.resid = self.res[val.DanjiriNews.length-1].news_id;

                        self.$forceUpdate()
                        done();
                    }, 1000);

                }).catch((err)=>{   //通信エラー時
                    console.log(err)
                }).finally(()=>{
                    self.alertLoadingVisible = false    //Loadingダイアログを非表示
                });
            },
            handleDel(){
                let self = this
                //---パスワードチェック用
                let inputValue = document.getElementById("newsPassword").value;
                let params = {
                    news_id: self.news_id,
                    password: inputValue
                }
                let checkPassAPI = self.$_mixAPI_checkNewsPassword(params);

                //---パス未入力チェック---
                if(inputValue == ''){
                    self.ErrMessage = "パスワードを入力してください。";
                    self.alertErrPassVisible = true;                     //エラーダイアログを非表示
                    //self.alertPassVisible = false;                       //Pass入力ダイアログを非表示
                    return;
                }

                //---◆passwordチェック---
                checkPassAPI.then(function(val){

                    if(val.result == true){
                        //---削除用
                        let DeleteParams = { news_id: self.news_id }
                        let DeleteAPI = self.$_mixAPI_postdeleteNews(DeleteParams);
                        //---◆削除処理---
                        DeleteAPI.then(function(delval){
                            if(delval.result == true){
                                self.alertSuccessDeleteVisible = true;
                                document.getElementById("newsPassword").value = '';
                            }else{
                                self.ErrMessage = "削除に失敗しました。";
                                self.alertErrPassVisible = true;
                                document.getElementById("newsPassword").value = '';     //入力値をクリア
                                return;
                            }

                        }).catch((err)=>{   //通信エラー時
                            console.log(err);
                            self.alertErrorVisible = true;       //エラーダイアログ表示
                        }).finally(()=>{
                        });

                    }else{
                        self.ErrMessage = "パスワードが違います。";
                        self.alertErrPassVisible = true;
                        document.getElementById("newsPassword").value = '';     //入力値をクリア
                        return;
                    }

                }).catch((err)=>{   //通信エラー時
                    console.log(err);
                    self.alertErrorVisible = true;              //エラーダイアログ表示
                }).finally(()=>{
                    self.alertPassVisible = false;              //Pass入力ダイアログを非表示
                    //return this.mixPC_PrevComponentToCurrntPage
                });
            },
            handleEdit(){
                let self = this
                //---パスワードチェック用
                let inputValue = document.getElementById("newsPassword").value;
                let params = {
                    news_id: self.news_id,
                    password: inputValue
                }
                let checkPassAPI = self.$_mixAPI_checkNewsPassword(params);
                //---パス未入力チェック---
                if(inputValue === ''){
                    self.ErrMessage = "パスワードを入力してください。";
                    self.alertErrPassVisible = true;                     //エラーダイアログを非表示
                    //self.alertPassVisible = false;                       //Pass入力ダイアログを非表示
                    return;
                }
                //---◆passwordチェック---
                checkPassAPI.then(function(val){
                    if(val.result === true){
                        //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                        self.$_SetmixPC_CurrentPage_Key('newsedit')
                        self.$_setPage('news')
                        self.$_setNewsID(self.news_id)
                    }else{
                        self.ErrMessage = "パスワードが違います。";
                        self.alertErrPassVisible = true;
                        document.getElementById("newsPassword").value = '';     //入力値をクリア
                        return;
                    }
                }).catch((err)=>{   //通信エラー時
                    console.log(err);
                    self.alertErrorVisible = true;              //エラーダイアログ表示
                }).finally(()=>{
                    self.alertPassVisible = false;              //Pass入力ダイアログを非表示
                });
            },
            infiniteHandler($state) {
                let self = this;
                let params = {
                    uuid: this.$_uuid(),
                    festival_id: this.$_festivalIDS(),
                    town_id: this.$_TownID(),
                    min_news_id: self.resid,
                    limit_number: this.end,
                }
                let getResultAPI = this.$_mixAPI_getDanjiriNews(params);
                getResultAPI.then(function(val){
                    // 表示するデータが無い場合
                    if(val.DanjiriNews.length == 0){
                        $state.complete()
                        return;
                    } else {
                        $state.loaded();
                    }
                    //表示データ格納
                    if(self.resid == 0){
                        self.res = val.DanjiriNews;
                        self.resid = self.res[val.DanjiriNews.length-1].news_id;
                    }else{
                        for(let i=0; i < val.DanjiriNews.length; i++){
                            self.res.push(val.DanjiriNews[i]);
                        }
                        self.resid = self.res[self.start].news_id;
                    }
                    if (val.is_last[0] == false) {
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                    self.start += val.DanjiriNews.length-1;
                }).catch((err)=>{   //通信エラー時
                    if (err.response.status == 404) {
                        $state.complete()
                    }
                }).finally(()=>{
                    self.alertLoadingVisible = false    //Loadingダイアログを非表示
                });
            },
            handleCancel() {
                this.alertPassVisible = false;                  //Pass入力ダイアログを非表示
            },
            Post(done) {
                let self = this;
                let params = {
                    festival_id: this.$_festivalIDS(),
                    town_id: this.$_TownID,
                }
                let getResultAPI = this.$_mixAPI_getDanjiriNews(params);
                getResultAPI.then(function(val){
                    // 表示するデータが無い場合
                    if(val.DanjiriNews.length == 0){
                        setTimeout(() => {
                            done();
                        }, 1000);
                        return;
                    }
                    setTimeout(() => {
                        self.res = val.DanjiriNews;
                        self.resid = self.res[val.DanjiriNews.length-1].news_id;

                        self.$forceUpdate()
                        done();
                    }, 1000);
                }).catch((err)=>{   //通信エラー時
                    console.log(err)
                }).finally(()=>{
                    self.alertLoadingVisible = false    //Loadingダイアログを非表示
                    self.alertSuccessDeleteVisible = false
                    self.dialogVisible = false
                });
            },
            handleEditCancel() {
                this.alertEditPassVisible = false;                  //Pass入力ダイアログを非表示
            },
        },
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .main{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        background: #fffbea;
        border-radius: 10px;
        width: 92%;
        margin: 3%;
        padding: 1%;
    }
    .body{
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        background: #fffbea;
        border-radius: 10px;
        width: 92%;
        margin: 1% 3%;
        padding: 1%;
    }
    .dialogbtn{
        display: flex;
    }
    #border{
        margin-top: 5%;
        border-bottom: 2px solid #b58012;
    }
    .title {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        white-space: pre-wrap;
        word-break: break-all;
        margin: 5px 0 20px;
    }
    #title{
        width: calc(100% - 30px);
        margin: 0;
        font-size: 20px;
    }
    #more{
        width: 30px;
        float: right;
        color: darkgrey;
    }
    #date-time{
        text-align: justify;
        color: #9e9e9e;
        font-size: 90%;
        margin: 0;
    }
    img{
        width: 99%;
    }
    #explanation{
        text-align: justify;
        margin-top: 5%;
        white-space: pre-wrap;
        word-break: break-all;
    }
    .submission-dialog{
        text-align: center;
    }
    #delete{
        border-bottom: 0.5px solid darkgrey;
        padding-bottom: 5%;
        color: #f74747;
    }
    #handledel{
        color: #f74747;
    }
    #edit{
        border-bottom: 0.5px solid darkgrey;
        padding-bottom: 5%;
    }
    .dialogbtn{
        display: flex;
    }
    .center{
        margin-bottom: 2%;
        padding: 0;
    }
    #pull-hook{
        padding-top: 15px;
    }
    .loading {
        color: white;
    }
</style>
